import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AccessControl = ({
  permissionsRequired,
  groupsRequired,
  userId,
  children,
}) => {
  const logged = useSelector((state) => state.auth.logged);
  const groups = useSelector((state) => state.auth.groups);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    if (permissionsRequired) {
      permissionsRequired.push("ADMIN");
      if (
        logged.accesses.some((permission) =>
          permissionsRequired.includes(permission.code)
        )
      ) {
        setShow(true);
      }
    }

    // console.log(groupsRequired);
    if (groupsRequired && groups) {
      if (
        groups.some(
          (group) =>
            groupsRequired &&
            groupsRequired.some(
              (item) =>
                item &&
                item.identifier &&
                group.identifier &&
                item.identifier === group.identifier
            )
        )
      ) {
        setShow(true);
      }
    }
    if (userId) {
      if (logged.id === userId) {
        setShow(true);
      }
    }
  }, [userId, groupsRequired, permissionsRequired]);

  return (
    <React.Fragment>
      {show && (
        <>
          {React.Children.map(children, (child, index) => {
            return <>{child}</>;
          })}
        </>
      )}
    </React.Fragment>
  );
};

export default AccessControl;
