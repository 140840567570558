import axios from 'axios';
import { API } from '../../config/constants';

export const handleError = (error) => {
    // https://github.com/axios/axios#handling-errors
    const err = { error: true, status: 0, msg: [] }
    if (error.response) {
        err.status = error.response.status;
        err.msg = error.response.data.err;
    } else if (error.request) {
        err.status = 500;
        err.msg = ['Banco de dados desconectado!'];
    } else {
        err.status = 500;
        err.msg = [error.message];
    }
    return err;
}

const api = axios.create({
    baseURL: API.BACKEND
});

export const apiGet = (url, data, options) => api.get(url, data, options).then(result => result.data).catch(err => handleError(err));
export const apiPost = (url, data, options) => api.post(url, data, options).then(result => result.data).catch(err => handleError(err));
export const apiPut = (url, data, options) => api.put(url, data, options).then(result => result.data).catch(err => handleError(err));
export const apiPatch = (url, data, options) => api.patch(url, data, options).then(result => result.data).catch(err => handleError(err));
export const apiDelete = (url, data, options) => api.delete(url, data, options).then(result => result.data).catch(err => handleError(err));