import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import {
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import { PERMISSION, ROUTE } from "../config/constants";
import { colorText } from "../config/colors";

const internalStyles = makeStyles((theme) => ({
  links: {
    textDecoration: "none",
    color: colorText,
  },
  icon: {
    textAlign: "center",
    width: "1.5em",
    height: "1em",
    fontSize: 20,
    marginLeft: 2,
  },
}));

const MenuLinkCustom = ({
  to = ROUTE.HOME,
  faClass = "fas fa-home",
  linkPrimaryName = "Home",
  linkSecondaryName,
  permission = null,
}) => {
  const internalClasses = internalStyles();
  const permissions = useSelector((state) => state.auth.permissions);

  return (
    permissions.some(
      (perm) =>
        perm === PERMISSION.ADMIN || perm === permission || permission === null
    ) && (
      <ListItem
        button
        component={NavLink}
        to={to}
        className={clsx(internalClasses.listItem, {
          "Mui-selected": window.location.pathname === to, // Adicione classe ativa manualmente
        })}
      >
        <ListItemIcon>
          <Icon className={clsx(faClass, internalClasses.icon)} />
        </ListItemIcon>
        <ListItemText
          primary={linkPrimaryName}
          secondary={linkSecondaryName ? linkSecondaryName : ""}
        />
      </ListItem>
    )
  );
};

export default MenuLinkCustom;
