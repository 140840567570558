import { all, takeLatest } from 'redux-saga/effects';

import * as AuthTypes from './auth/types';
import * as AuxTypes from './aux/types';
import * as UtilsTypes from './utils/types';

import * as AuthSagas from './auth/sagas';
import * as AuxSagas from './aux/sagas';
import * as UtilsSagas from './utils/sagas';

export default function* rootSaga() {
    return yield all([
        // AUTH
        takeLatest(AuthTypes.LOGIN, AuthSagas.login),
        takeLatest(AuthTypes.LOGOUT, AuthSagas.logout),
        takeLatest(AuthTypes.UNAUTHENTICATED, AuthSagas.unauthenticated),
        takeLatest(AuthTypes.UNAUTHORIZED, AuthSagas.unauthorized),
        takeLatest(AuthTypes.GET_LOGGED, AuthSagas.getLogged),
        takeLatest(AuthTypes.SET_LOGGED, AuthSagas.setLogged),
        // AUX
        takeLatest(AuxTypes.GET_ACCOUNT_TYPES, AuxSagas.getAccountTypes),
        takeLatest(AuxTypes.GET_BRANDS, AuxSagas.getBrands),
        takeLatest(AuxTypes.GET_PROVIDERS, AuxSagas.getProviders),
        takeLatest(AuxTypes.GET_CLIENTS, AuxSagas.getClients),
        takeLatest(AuxTypes.GET_CATEGORIES, AuxSagas.getCategories),
        takeLatest(AuxTypes.GET_MODELS, AuxSagas.getModels),
        takeLatest(AuxTypes.GET_SERVICES, AuxSagas.getServices),
        takeLatest(AuxTypes.GET_USERS, AuxSagas.getUsers),
        takeLatest(AuxTypes.GET_CONFIGURATIONS, AuxSagas.getConfigurations),
        // UTILS
        takeLatest(UtilsTypes.GET_COUNT_NEW_MESSAGES, UtilsSagas.getCountNewMessages),
    ]);
};