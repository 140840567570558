import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
  Chip,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import * as AccountApi from "@api/backend/account";
import * as Helper from "../../../../utils/helper";
import { format, isAfter, parse } from "date-fns";
import {
  colorCancelLight,
  colorSuccessLight,
  colorWarningLight,
} from "../../../../config/colors";
import { Delete as DeleteIcon } from "@material-ui/icons";
import useStyles from "../../../../styles";

const internalStyles = makeStyles((theme) => ({
  row: {
    background: theme.palette.secondary.main,
  },
}));

const AccountAssociateTable = ({ data, accountId, childrensTemp }) => {
  const [accounts, setAccounts] = useState([]);
  const [childrens, setChildrens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientAccounts, setClientAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [selectedItemsCombined, setSelectedItemsCombined] = useState([]);
  const [selectedItemsSuggested, setSelectedItemsSuggested] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const internalClasses = internalStyles();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let dueDateTemp = data.due_date ? new Date(data.due_date) : new Date();
      let temp = await AccountApi.getAccountSugest({
        dueDate: new Date(dueDateTemp),
        clientId: data.client_id,
      });
      if (temp && temp.data) {
        setAccounts(temp.data);
      } else {
        console.error('Falha ao buscar contas sugeridas');
      }
      setLoading(false);
    };
    if (data.client_id !== 0 && data) {
      fetchData();
    }
    setChildrens(childrensTemp);
  }, [data, childrensTemp, forceUpdate]);

  const handleToggleCombined = (item) => {
    const selectedIndex = selectedItemsCombined.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelected = [...selectedItemsCombined];

    if (selectedIndex === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedItemsCombined(newSelected);
  };

  const handleToggleSuggested = (item) => {
    const selectedIndex = selectedItemsSuggested.findIndex(
      (selectedItem) => selectedItem.account_id === item.account_id
    );
    let newSelected = [...selectedItemsSuggested];

    if (selectedIndex === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedItemsSuggested(newSelected);
  };

  const handleLoadOptions = async () => {
    setLoading(true);
    const clientAccountsTemp = await AccountApi.getAccountSugestByClient(
      data.client_id
    );
    setClientAccounts(clientAccountsTemp);
    setLoading(false);
  };

  const handleSelectChange = (event) => {
    setSelectedAccountId(event.target.value);
    const selectedAccount = clientAccounts.find(
      (account) => account.account_id === event.target.value
    );
    if (selectedAccount) {
      setAccounts((prevAccounts) => [...prevAccounts, selectedAccount]);
    }
  };

  const handleRemoveItem = (accountId) => {
    setAccounts((prevAccounts) =>
      prevAccounts.filter((account) => account.account_id !== accountId)
    );
    setSelectedItemsCombined([]);
    setSelectedItemsSuggested([]);
  };

  const handleUncombineAccounts = async () => {
    let array = [...selectedItemsCombined, ...selectedItemsSuggested].map(
      (account) => (account.id !== undefined ? account.id : account.account_id)
    );
    await AccountApi.decombineAccounts(array);

    setForceUpdate(!forceUpdate);
  };

  const handleCombineAccounts = async () => {
    let array = [...selectedItemsCombined, ...selectedItemsSuggested].map(
      (account) => (account.id !== undefined ? account.id : account.account_id)
    );
    await AccountApi.combineAccounts(accountId, array);

    setForceUpdate(!forceUpdate);
  };

  const handleUncombineUniqueAccount = async (accountId) => {
    await AccountApi.decombineAccounts([accountId]);

    setForceUpdate(!forceUpdate);
  };

  const getStatus = (status, dueDate) => {
    switch (status) {
      case 0:
        if (isAfter(new Date(), new Date(dueDate))) {
          return (
            <Chip
              size="small"
              label="Atrasada"
              style={{ background: colorCancelLight }}
              variant="default"
            />
          );
        }
        return (
          <Chip
            size="small"
            label="Vencidos"
            style={{ background: colorWarningLight }}
            variant="default"
          />
        );
      case 1:
        return (
          <Chip
            size="small"
            label="Pago"
            style={{ background: colorSuccessLight }}
            variant="default"
          />
        );
      case 2:
        return (
          <Chip
            size="small"
            label="Cancelada"
            style={{ background: colorCancelLight }}
            variant="default"
          />
        );
      default:
        return null;
    }
  };

  // const combinedSum = accounts.reduce(
  //   (acc, row) => Number(acc) + Number(row.total),
  //   0
  // );
  // const sugestedSum = childrens.reduce(
  //   (acc, row) => Number(acc) + Number(row.account_receife.total),
  //   0
  // );

  return (
    <>
      <FormControl variant="outlined" fullWidth style={{ marginBottom: "5x" }}>
        <InputLabel id="select-accounts">Todas as Contas</InputLabel>
        <Select
          labelId="select-accounts"
          label="Todas as Contas"
          onOpen={handleLoadOptions}
          value={selectedAccountId}
          onChange={handleSelectChange}
        >
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={25} />
            </MenuItem>
          ) : (
            clientAccounts
              .filter(
                (account) =>
                  !accounts?.some(
                    (acc) => acc.account_id === account.account_id
                  )
              )
              .map((account) => (
                <MenuItem key={account.account_id} value={account.account_id}>
                  {account.due_date} - {account.description}
                </MenuItem>
              ))
          )}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table aria-label="contas table">
          <TableHead>
            <TableRow>
              <TableCell align="left" minWidth></TableCell>
              <TableCell align="center">Vencimento</TableCell>
              <TableCell align="center">Conta</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Desconto</TableCell>
              <TableCell align="center">Juros</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center" minWidth></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={internalClasses.row}>
              <TableCell size="small" align="center" colSpan={8}>
                <Typography className={classes.title}>
                  Contas Combinadas
                </Typography>
              </TableCell>
            </TableRow>
            {childrens &&
              childrens.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    padding="checkbox"
                    size="small"
                    align="center"
                    minWidth
                  >
                    <Checkbox
                      color="primary"
                      checked={selectedItemsCombined?.some(
                        (selectedItem) => selectedItem.id === item.id
                      )}
                      onChange={() => handleToggleCombined(item)}
                    />
                  </TableCell>
                  <TableCell size="small" align="center">
                    {item.account_receife.due_date
                      ? format(new Date(item.account_receife.due_date), "dd/MM")
                      : "Data inválida"}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {item.account_receife.description}
                    {/* &nbsp;
                    <span style={{ fontSize: "20px" }}>+</span> */}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {"R$ " +
                      Helper.numberToReal(String(item.account_receife.price))}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {"R$ " +
                      Helper.numberToReal(
                        String(item.account_receife.discount)
                      )}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {"R$ " +
                      Helper.numberToReal(String(item.account_receife.tax))}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {getStatus(
                      item.account_receife.received,
                      item.account_receife.due_date
                    )}
                  </TableCell>
                  <TableCell size="small" align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleUncombineUniqueAccount(item.id)}
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {/* <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                <Typography variant="button">TOTAL DA COMBINAÇÃO</Typography>
              </TableCell>
              <TableCell size="small" colSpan={4} align="left">
                <Typography variant="button">
                  R${combinedSum.toFixed(2)} {"+ (R$" + data.price + ")"}
                </Typography>
              </TableCell>
            </TableRow> */}
            <TableRow className={internalClasses.row}>
              <TableCell size="small" align="center" colSpan={8}>
                <Typography className={classes.title}>
                  Contas Sugeridas
                </Typography>
              </TableCell>
            </TableRow>
            {accounts &&
              accounts
                .filter(
                  (item) =>
                    !childrens?.some((child) => child.id === item.account_id)
                )
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      padding="checkbox"
                      size="small"
                      align="center"
                      minWidth
                    >
                      <Checkbox
                        color="primary"
                        checked={selectedItemsSuggested?.some(
                          (selectedItem) =>
                            selectedItem.account_id === item.account_id
                        )}
                        onChange={() => handleToggleSuggested(item)}
                      />
                    </TableCell>
                    <TableCell size="small" align="center">
                      {format(new Date(item.due_date), "dd/MM")}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {item.description}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {"R$ " + Helper.numberToReal(String(item.price))}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {"R$ " + Helper.numberToReal(String(item.discount))}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {"R$ " + Helper.numberToReal(String(item.tax))}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {getStatus(item.received, item.due_date)}
                    </TableCell>
                    <TableCell size="small" align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveItem(item.account_id)}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            {/* <TableRow>
              <TableCell size="small" colSpan={3} align="right">
                <Typography variant="button">TOTAL DA SUGESTÃO</Typography>
              </TableCell>
              <TableCell size="small" colSpan={4} align="left">
                <Typography variant="button">
                  R${sugestedSum.toFixed(2)}
                </Typography>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell size="small" colSpan={8} align="right">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={handleUncombineAccounts}
                  disabled={
                    selectedItemsSuggested.length > 0 ||
                    selectedItemsCombined.length == 0
                  }
                >
                  Remover Combinação
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={handleCombineAccounts}
                  disabled={
                    selectedItemsCombined.length > 0 ||
                    selectedItemsSuggested.length == 0
                  }
                >
                  Combinar Contas
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccountAssociateTable;
