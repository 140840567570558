import React, { useEffect } from "react";
import { Route, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as AuthActions from "../store/ducks/auth/actions";
import Layout from "./Layout";
import LayoutPdf from "./LayoutPdf";
import AutoReload from "./AutoReload";
import { HeaderProvider } from "./HeaderProvider";

const RouteCustom = ({
  privateRoute,
  pdfRoute,
  removeMenu = false,
  viewFaClass,
  component: Component,
  customStyle,
  ...rest
}) => {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.auth.logged);
  const params = useParams() ?? {};

  useEffect(() => {
    if (privateRoute || pdfRoute) {
      dispatch(AuthActions.getLogged());
    }
  }, [dispatch, privateRoute, pdfRoute]);

  const retorno = () => {
    if (pdfRoute || (privateRoute && pdfRoute)) {
      if (logged)
        return (
          <LayoutPdf viewFaClass={viewFaClass}>
            <Component match={{ params }} />
          </LayoutPdf>
        );
      else return <AutoReload />;
    } else if (privateRoute) {
      if (logged)
        return (
          <HeaderProvider>
            <Layout
              viewFaClass={viewFaClass}
              removeMenu={removeMenu}
              customStyle={customStyle}
            >
              <Component match={{ params }} />
            </Layout>
          </HeaderProvider>
        );
      else return <AutoReload />;
    } else {
      return <Component match={{ params }} />;
    }
  };

  return retorno();
};

export default RouteCustom;
