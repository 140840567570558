import * as Types from "./types";

const INITIAL_STATE = {
  logged: null,
  permissions: null,
  token: null,
  groups: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOGOUT:
      // reducer reset on store / saga remove on localStorage
      return { ...INITIAL_STATE };
    case Types.SET_LOGGED:
      // reducer set on store / saga set on localStorage
      return {
        ...state,
        logged: action.payload.logged,
        permissions: action.payload.logged.accesses
          ? action.payload.logged.accesses.map((access) => access.code)
          : null,
        token: action.payload.token,
        groups: action.payload.groups,
      };
    default:
      return state;
  }
};

export default reducer;
