import { call, put, all } from "redux-saga/effects";

import { ROUTE, LOCAL_STORAGE, START_PAGE } from "../../../config/constants";
import { backendGet, backendPost } from "../../../config/api";
import history from "../../../config/history";
import * as AuthActions from "./actions";
import * as UtilsActions from "../utils/actions";
import { useSelector } from "react-redux";

const RoutesConfig = (role) => {
  if (role) {
    const roleConfig = START_PAGE.filter(
      (item) => item.role === role.toLowerCase()
    );

    if (roleConfig.length > 0) {
      return roleConfig[0].page;
    }
  }
  return ROUTE.HOME;
};

export function* login(action) {
  try {
    const data = yield call(backendPost, "auth/login", action.payload);
    if (!data.error) {
      yield put(AuthActions.setLogged(data));
      action.callback(data);
    } else yield handleError(data);
  } catch (err) {
    console.log(err);
    yield put(
      UtilsActions.enqueueSnackbar(
        "error",
        "(#AS-LO01) - Erro ao realizar login no sistema!"
      )
    );
  }
}

export function* logout() {
  yield put(AuthActions.nuke());
  yield localStorage.removeItem(LOCAL_STORAGE);
  window.location.pathname = ROUTE.LOGIN;
}

export function* getLogged() {
  try {
    const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
    if (logged) {
      const data = yield call(backendGet, "auth/logged", {
        headers: { olsystem: logged.token },
      });
      // console.log(data);
      if (!data.error) {
        yield put(AuthActions.setLogged(data));
      } else yield handleError(data);
    } else yield put(AuthActions.unauthenticated());
  } catch (err) {
    console.log("teste");
    yield put(
      UtilsActions.enqueueSnackbar(
        "error",
        "(#AS-GL01) - Erro ao buscar funcionário logado no sistema!"
      )
    );
  }
}

export function* setLogged(action) {
  // reducer set on store / saga set on localStorage
  yield localStorage.setItem(LOCAL_STORAGE, JSON.stringify(action.payload));
}

export function* unauthenticated() {
  // 401
  yield put(UtilsActions.enqueueSnackbar("error", "Desconectado!"));
  yield put(AuthActions.logout());
}

export function* unauthorized() {
  // 403
  yield put(UtilsActions.enqueueSnackbar("error", "Não autorizado!"));
  window.location.pathname = ROUTE.HOME;
}

// -------------------------------------------------- LOCAL FUNCTIONS
function* handleError(data) {
  if (data.status === 401) yield put(AuthActions.unauthenticated());
  else if (data.status === 403) yield put(AuthActions.unauthorized());
  else
    yield all(
      data.msg.map((m) => put(UtilsActions.enqueueSnackbar("error", m)))
    );
}
