export const GET_ACCOUNT_TYPES = "@aux/GET_ACCOUNT_TYPES";
export const SET_ACCOUNT_TYPES = "@aux/SET_ACCOUNT_TYPES";
export const GET_PROVIDERS = "@aux/GET_PROVIDERS";
export const SET_PROVIDERS = "@aux/SET_PROVIDERS";
export const GET_BRANDS = "@aux/GET_BRANDS";
export const SET_BRANDS = "@aux/SET_BRANDS";
export const GET_CATEGORIES = "@aux/GET_CATEGORIES";
export const SET_CATEGORIES = "@aux/SET_CATEGORIES";
export const GET_CLIENTS = "@aux/GET_CLIENTS";
export const SET_CLIENTS = "@aux/SET_CLIENTS";
export const GET_CONFIGURATIONS = "@aux/GET_CONFIGURATIONS";
export const SET_CONFIGURATIONS = "@aux/SET_CONFIGURATIONS";
export const GET_MODELS = "@aux/GET_MODELS";
export const SET_MODELS = "@aux/SET_MODELS";
export const GET_SERVICES = "@aux/GET_SERVICES";
export const SET_SERVICES = "@aux/SET_SERVICES";
export const GET_USERS = "@aux/GET_USERS";
export const SET_USERS = "@aux/SET_USERS";