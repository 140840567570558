import { combineReducers } from 'redux';

import * as AuthTypes from './auth/types';
import auth from './auth/reducer';
import aux from './aux/reducer';
import utils from './utils/reducer';


// new
// Combine all reducers.
const appReducer = combineReducers({
    auth, aux, utils,
});
const rootReducer = (state, action) => {
    if (action.type === AuthTypes.NUKE)
        state = undefined;
    return appReducer(state, action);
};
export default rootReducer;