import * as Types from './types';

export const login = (payload, callback) => ({ type: Types.LOGIN, payload, callback });
export const logout = () => ({ type: Types.LOGOUT });

export const getLogged = () => ({ type: Types.GET_LOGGED });
export const setLogged = (payload) => ({ type: Types.SET_LOGGED, payload });

export const unauthenticated = () => ({ type: Types.UNAUTHENTICATED });
export const unauthorized = () => ({ type: Types.UNAUTHORIZED });

export const nuke = () => ({ type: Types.NUKE });