import * as Types from './types';

const INITIAL_LOADING = {
	start: false,
	title: '',
	message: '',
};
const INITIAL_STATE = {
    loading: INITIAL_LOADING,
    notifications: [],
    countNewMessages: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case Types.SET_LOADING:
            return { ...state,
                loading: {
                    start: true,
                    title: action.payload.title,
                    message: action.payload.message,
                }
            }
        case Types.RESET_LOADING:
            return { ...state, loading: INITIAL_LOADING }
        case Types.ENQUEUE_SNACKBAR:
            return { ...state,
                notifications: [ ...state.notifications, { key: action.key, ...action.notification }],
            };
        case Types.CLOSE_SNACKBAR:
            return { ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };
        case Types.REMOVE_SNACKBAR:
            return { ...state,
                notifications: state.notifications.filter(notification => notification.key !== action.key )
            };
        case Types.SET_COUNT_NEW_MESSAGES:
            return { ...state, countNewMessages: action.payload };
		default:
			return state;
	}
}

export default reducer;
