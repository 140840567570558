import { call, put, all, select } from 'redux-saga/effects';

import { LOCAL_STORAGE, SAGA_AUX_WAITING_TIME } from '../../../config/constants';
import { backendGet } from '../../../config/api';
import * as AuxActions from './actions';
import * as UtilsActions from '../utils/actions';

export function* getAccountTypes() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const accountTypesDate = yield select(state => state.aux.accountTypesDate);
            if (!accountTypesDate || accountTypesDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'accountTypes/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setAccountTypes(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GAT01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GAT02) - Erro!')); }
}

export function* getBrands() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const brandsDate = yield select(state => state.aux.brandsDate);
            if (!brandsDate || brandsDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'brands/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setBrands(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GB01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GB02) - Erro!')); }
}

export function* getProviders() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const providersDate = yield select(state => state.aux.providersDate);
            if (!providersDate || providersDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'providers/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setProviders(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GP01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GP02) - Erro!')); }
}

export function* getClients() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const clientsDate = yield select(state => state.aux.clientsDate);
            if (!clientsDate || clientsDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'clients/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setClients(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GC01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GC02) - Erro!')); }
}

export function* getCategories() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const categoriesDate = yield select(state => state.aux.categoriesDate);
            if (!categoriesDate || categoriesDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'categories/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setCategories(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GC01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GC02) - Erro!')); }
}

export function* getModels() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const modelsDate = yield select(state => state.aux.modelsDate);
            if (!modelsDate || modelsDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'models/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setModels(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GM01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GM02) - Erro!')); }
}

export function* getServices() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const servicesDate = yield select(state => state.aux.servicesDate);
            if (!servicesDate || servicesDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'services/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setServices(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GS01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GS02) - Erro!')); }
}

export function* getUsers() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const usersDate = yield select(state => state.aux.usersDate);
            if (!usersDate || usersDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'users/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setUsers(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GU01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GU02) - Erro!')); }
}

export function* getConfigurations() {
    try {
        const logged = JSON.parse(localStorage.getItem(LOCAL_STORAGE));
        if (logged) {
            const configurationsDate = yield select(state => state.aux.configurationsDate);
            if (!configurationsDate || configurationsDate < Date.now() - SAGA_AUX_WAITING_TIME) {
                const data = yield call(backendGet, 'configurations/aux', { headers: { olsystem: logged.token } } );
                if (!data.error) yield put(AuxActions.setConfigurations(data.data));
                else yield handleError(data);
            }
        } else yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GC01) - Erro!'));
    } catch(err) { yield put(UtilsActions.enqueueSnackbar('error', '(#AXS-GC02) - Erro!')); }
}

// -------------------------------------------------- LOCAL FUNCTIONS
function* handleError(data) {
    yield all(data.msg.map(m => put(UtilsActions.enqueueSnackbar('error', m))));
}