import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogContent, Typography, LinearProgress, Grid, makeStyles } from '@material-ui/core';

import { colorText, colorAutoReloadDark, colorAutoReloadLight } from '../config/colors'

// ------------------------------------------------------------------------------------------------------------------ UTILS
const useStyles = makeStyles(() => ({
	titleAlert: {
		marginTop: "10px",
		fontSize: 25,
		fontWeight: 600,
		color: colorText,
		textAlign: "center",
	},
	messageAlert: {
		marginBottom: "10px",
		fontSize: 16,
		fontWeight: 400,
		color: colorText,
		textAlign: "center",
    },
    linearBar: {
        height: '15px',
        borderRadius: 5,
    },
	borderRadius: {
		borderRadius: 30
	},
	colorLight: {
		backgroundColor: colorAutoReloadLight
	},
	colorDark: {
		backgroundColor: colorAutoReloadDark
	},
}));

const AutoReload = () => {
    const classes = useStyles();
    const history = useNavigate ();

    useEffect(() => {
		let total = setTimeout(history.go, 30000);
        return () => clearTimeout(total)
    }, [history]);
   
	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open
			className={classes.borderRadius}
		>
			<DialogContent>
				<LinearProgress className={classes.linearBar} classes={{
					indeterminate: classes.colorDark, bar1Indeterminate: classes.colorLight, bar2Indeterminate: classes.colorLight
				}} />
				<Grid container>
					<Grid item xs={12}>
                        <Typography className={classes.titleAlert}>Carregando...</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.messageAlert}>A página será recarregada em breve!</Typography>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

export default AutoReload;