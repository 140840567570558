import React, { createContext, useState, useContext } from "react";

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerComponent, setHeaderComponent] = useState(null);
  const [isCustomHeader, setIsCustomHeader] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const triggerUpdate = () => setForceUpdate(prev => !prev);


  return (
    <HeaderContext.Provider
      value={{ headerComponent, setHeaderComponent, isCustomHeader, setIsCustomHeader, triggerUpdate }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);
