import React from "react";

import { Typography, Link } from "@material-ui/core";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{marginTop: "10px"}}>
      
      {"Copyright © "} {new Date().getFullYear()}
      {"."}
      <Link color="inherit" href="http://onloc.com.br/">
        On.Loc
      </Link>
      .<br />
      Todos os Direitos Reservados.
      <br />
    </Typography>
  );
};

export default Copyright;
