import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";

import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Toolbar,
  Icon,
  Typography,
  Hidden,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  Badge,
  makeStyles,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@material-ui/icons";

import MenuLinkCustom from "./MenuLinkCustom";
import Copyright from "./Copyright";
import * as AuthActions from "../store/ducks/auth/actions";
// import * as UtilsActions from '../store/ducks/utils/actions';
import {
  IS_PRODUCTION,
  AMBIENT_ENV,
  PERMISSION,
  ROUTE,
} from "../config/constants";
import {
  colorWhite,
  colorGreyLight,
  colorCancelDark,
  colorSecondary,
  colorWarningDark,
} from "../config/colors";
import Logo from "../assets/logo-horizontal-branco.png";
import { useHeader } from "./HeaderProvider";

const drawerHeight = "100vh";
const drawerWidth = 240;

const internalStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  toolbar: {
    paddingLeft: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    width: "60px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: drawerHeight,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    /* hide scroolbar from menu */
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      // chrome, safari, opera
      display: "none",
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    width: "1.5em",
    height: "1em",
    fontSize: 20,
    marginLeft: 2,
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: colorWhite,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  dev: {
    backgroundColor: colorCancelDark,
  },
  test: {
    backgroundColor: colorWarningDark,
  },
  logo: {
    padding: theme.spacing(1),
    maxWidth: "10%",
    maxHeight: "10%",
  },
}));

const Layout = ({
  children,
  viewFaClass = "fas fa-robot",
  removeMenu,
  customStyle = false,
}) => {
  const internalClasses = internalStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { headerComponent, isCustomHeader } = useHeader();

  const logged = useSelector((state) => state.auth.logged);
  const countNewMessages = useSelector((state) => state.utils.countNewMessages);

  const [showDialogLogout, setShowDialogLogout] = useState(false);
  const [menuLeftOpen, setMenuLeftOpen] = useState(false);
  const [isProduction, setIsProduction] = useState(IS_PRODUCTION);

  const handleSetIsProduction = useCallback(() => {
    if (isProduction === false) {
      setIsProduction((state) => !state);
      setTimeout(() => setIsProduction(IS_PRODUCTION), 30000); // 30s
    }
  }, [isProduction]);

  const handleShowDialogLogoutToggle = () => {
    setShowDialogLogout(!showDialogLogout);
  };
  const handleDrawerToggle = () => {
    setMenuLeftOpen(!menuLeftOpen);
  };
  const handleLogout = () => {
    dispatch(AuthActions.logout());
  };

  const drawer = logged && (
    <>
      <Divider />
      <List>
        <MenuLinkCustom />
        <MenuLinkCustom
          to={ROUTE.CLIENTS}
          permission={PERMISSION.CLIENT}
          faClass="fas fa-users"
          linkPrimaryName="Clientes"
        />
        <MenuLinkCustom
          to={ROUTE.PROPOSALS}
          permission={PERMISSION.PROPOSAL}
          faClass="fas fa-money-check-alt"
          linkPrimaryName="Orçamentos"
        />
        <MenuLinkCustom
          to={ROUTE.SERVICE_ORDERS}
          permission={PERMISSION.SERVICE_ORDER}
          faClass="fas fa-tools"
          linkPrimaryName="Ordens de Serviço"
        />
        <MenuLinkCustom
          to={ROUTE.PRODUCTS}
          permission={PERMISSION.PRODUCT}
          faClass="fas fa-laptop"
          linkPrimaryName="Produtos"
        />
        <MenuLinkCustom
          to={ROUTE.CALL}
          permission={PERMISSION.CALL}
          faClass="fas fa-comments"
          linkPrimaryName="Chamados"
        />
        <MenuLinkCustom
          to={ROUTE.FINANCIAL_HOME}
          permission={PERMISSION.FINANCIAL}
          faClass="fas fa-dollar-sign"
          linkPrimaryName="Financeiro"
        />
        <MenuLinkCustom
          to={ROUTE.QUOTATION_HOME}
          permission={PERMISSION.QUOTATION}
          faClass="fas fa-briefcase"
          linkPrimaryName="Orçamento"
        />
      </List>
      <Divider />
      <List>
        <MenuLinkCustom
          to={ROUTE.MENU}
          faClass="fas fa-th"
          linkPrimaryName="Menu"
        />
        {/* <MenuLinkCustom to={'/contrato/documento'} faClass="fas fa-file" linkPrimaryName="Contrato" /> */}
      </List>
      <Hidden smUp>
        <Divider />
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <Icon
                className={clsx("fas fa-sign-out-alt", internalClasses.icon)}
              />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Hidden>
    </>
  );

  return (
    logged && (
      <div className={internalClasses.root}>
        {/* -------------------------------------------------- MENU SUPERIOR */}

        <AppBar
          position="absolute"
          className={clsx(
            internalClasses.appBar,
            menuLeftOpen && internalClasses.appBarShift
          )}
          color="primary"
          classes={!IS_PRODUCTION && { root: internalClasses.dev }}
        >
          {isCustomHeader ? (
            <>{headerComponent}</>
          ) : (
            !removeMenu && (
              <Toolbar className={internalClasses.toolbar}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Abrir Menu"
                  onClick={handleDrawerToggle}
                  className={clsx(
                    internalClasses.menuButton,
                    menuLeftOpen && internalClasses.menuButtonHidden
                  )}
                >
                  <MenuIcon />
                </IconButton>
                <p>
                  <a href={ROUTE.HOME}>
                    {" "}
                    <img src={Logo} className={internalClasses.logo} />{" "}
                  </a>
                </p>
                <Hidden xsDown>
                  <Typography
                    style={{ whiteSpace: "nowrap", paddingRight: "10px" }}
                  >
                    {logged && logged.name
                      ? logged.name
                      : "Nome não disponível"}
                  </Typography>
                  <Divider
                    className={internalClasses.divider}
                    orientation="vertical"
                  />
                  <Button
                    style={{ color: colorWhite }}
                    onClick={handleShowDialogLogoutToggle}
                  >
                    Sair
                  </Button>
                  {!IS_PRODUCTION && (
                    <Button
                      style={{ color: colorWhite }}
                      onClick={handleSetIsProduction}
                    >
                      Cor
                    </Button>
                  )}
                </Hidden>
              </Toolbar>
            )
          )}
        </AppBar>

        {/* -------------------------------------------------- END MENU SUPERIOR */}
        {/* -------------------------------------------------- MENU LATERAL */}
        {/* -------------------------------------------------- MENU MOBILE */}
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {!removeMenu && (
          <Hidden smUp implementation="css">
            <Drawer
              // container={container}
              variant="temporary"
              // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={menuLeftOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: internalClasses.drawerPaper }}
              ModalProps={{ keepMounted: true }} // Better open performance on mobile.
            >
              <div className={internalClasses.toolbarIcon}>
                <IconButton onClick={handleDrawerToggle}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              {/* -------------------------------------------------- LINKS */}
              {drawer}
            </Drawer>
          </Hidden>
        )}
        {/* -------------------------------------------------- END MENU MOBILE */}
        {/* -------------------------------------------------- MENU DESKTOP */}
        {!removeMenu && (
          <Hidden xsDown>
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(
                  internalClasses.drawerPaper,
                  !menuLeftOpen && internalClasses.drawerPaperClose
                ),
              }}
              open={menuLeftOpen}
            >
              <div className={internalClasses.toolbarIcon}>
                <IconButton onClick={handleDrawerToggle}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              {/* -------------------------------------------------- LINKS */}
              {drawer}
            </Drawer>
          </Hidden>
        )}
        {/* -------------------------------------------------- END MENU DESKTOP */}
        {/* -------------------------------------------------- END MENU LATERAL */}

        {showDialogLogout && (
          <Dialog
            onClose={handleShowDialogLogoutToggle}
            open={showDialogLogout}
          >
            <DialogTitle>Deseja realmente SAIR?</DialogTitle>
            <Divider />
            <DialogContent style={{ margin: "10px 0" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleShowDialogLogoutToggle}
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleLogout}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Sair
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}

        {!!countNewMessages && (
          <div className={internalClasses.fab}>
            <Badge
              color="error"
              overlap="circle"
              badgeContent={countNewMessages}
              max={99}
            >
              <Fab color="primary" aria-label="add">
                <Icon className={clsx("fas fa-bell", internalClasses.icon)} />
              </Fab>
            </Badge>
          </div>
        )}

        <main className={!customStyle ? internalClasses.content : ""}>
          {!removeMenu && <div className={internalClasses.appBarSpacer} />}
          <Container maxWidth="lg" className={internalClasses.container}>
            <Grid container spacing={3}>
              {children}
            </Grid>
            {!removeMenu && (
              <Box pt={2}>
                <Copyright />
              </Box>
            )}
          </Container>
        </main>
      </div>
    )
  );
};

export default Layout;
