import { StyleSheet, Font } from "@react-pdf/renderer";

import RobotoRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoItalic from "../assets/fonts/Roboto/Roboto-Italic.ttf";
import RobotoBold from "../assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "../assets/fonts/Roboto/Roboto-BoldItalic.ttf";

Font.register({
  family: "Roboto",
  format: "truetype",
  fonts: [
    { src: RobotoRegular },
    { src: RobotoBold, fontWeight: "bold" },
    { src: RobotoItalic, fontWeight: "normal", fontStyle: "italic" },
    { src: RobotoBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});

export default StyleSheet.create({
  pdfViewer: {
    height: "calc(100vh - 108px)",
    width: "100%",
    border: "none",
    overflow: "hidden",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  noMargin: { margin: 0 },
  marginPatrimony: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 10,
    marginLeft: 10,
  },
  margin05: { marginTop: 4, marginBottom: 4, marginRight: 10, marginLeft: 10 },
  margin: { marginTop: 8, marginBottom: 8, marginRight: 10, marginLeft: 10 },
  margin2: { marginTop: 16, marginBottom: 16, marginRight: 10, marginLeft: 10 },
  margin3: { marginTop: 24, marginBottom: 24, marginRight: 10, marginLeft: 10 },
  margin4: { marginTop: 32, marginBottom: 32, marginRight: 10, marginLeft: 10 },
  margin5: { marginTop: 40, marginBottom: 40, marginRight: 10, marginLeft: 10 },
  paddingX: { paddingHorizontal: 40 },

  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  underline: { textDecoration: "underline" },

  alignCenter: { textAlign: "center" },
  alignLeft: { textAlign: "left" },
  alignRight: { textAlign: "right" },

  width5: { width: "5%" },
  width10: { width: "10%" },
  width125: { width: "12.5%" },
  width15: { width: "15%" },
  width175: { width: "17.5%" },
  width20: { width: "20%" },
  width25: { width: "25%" },
  width30: { width: "30%" },
  width33: { width: "33%" },
  width34: { width: "34%" },
  width375: { width: "37.5%" },
  width40: { width: "40%" },
  width45: { width: "45%" },
  width50: { width: "50%" },
  width55: { width: "55%" },
  width60: { width: "60%" },
  width625: { width: "62.5%" },
  width70: { width: "70%" },
  width75: { width: "75%" },
  width80: { width: "80%" },
  width85: { width: "85%" },
  width875: { width: "87.5%" },
  width90: { width: "90%" },
  width100: { width: "100%" },

  header: {
    fontSize: 12,
    marginBottom: 20,
    fontFamily: "Roboto",
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    marginHorizontal: 47,
    fontSize: 9, // 10
    fontFamily: "Roboto",
    textAlign: "center",
    color: "grey",
  },
  footerBorderTop: {
    paddingTop: 10,
    borderStyle: "solid",
    borderTop: 1,
    borderColor: "grey",
  },

  author: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Roboto",
    marginBottom: 40,
  },
  title: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 11,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  text: {
    fontSize: 9, // 10
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  textLower: {
    fontSize: 6, // 10
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  titleContract: {
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  subtitleContract: {
    fontSize: 11,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  textContract: {
    fontSize: 9,
    // lineHeight: 1.25,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  image: {
    marginBottom: 15,
    marginHorizontal: 115, // 100
  },
  footerImage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  headerImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },

  viewGrid: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  viewGridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  table: { display: "table" },
  tableStripped: { backgroundColor: "#fff" },
  tableHeader: {
    borderStyle: "solid",
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  // table2: { display: "table", },
  // tableStripped: { backgroundColor: '#eee', },
  // tableHeader: {
  //     backgroundColor: '#333',
  //     color: '#fff',
  //     fontFamily: 'Roboto',
  //     fontWeight: 'bold'
  // },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: 3,
    fontSize: 9, // 10
    fontFamily: "Roboto",
  },
  tableCellContract: {
    margin: 3,
    fontSize: 8,
    fontFamily: "Roboto",
  },

  borderTop: {
    borderStyle: "solid",
    borderTopWidth: 1,
  },
  borderUpLeft: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  borderBottom: {
    borderStyle: "solid",
    borderBottomWidth: 1,
  },
  borderBottomRight: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  borderAll: {
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  borderTopLeftRight: {
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  borderTopLeftBottom: {
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 0,
  },
  borderTopRightBottom: {
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 1,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  titlePatrimony: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Roboto",
  },
  checkbox: {
    width: 10,
    height: 10,
    border: "1pt solid black",
    position: "absolute",
    top: 8,
  },
});
