import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { CssBaseline, createTheme, ThemeProvider } from "@material-ui/core";
// import { StylesProvider } from "@material-ui/core/styles";
import { colorSecondary } from "../config/colors";
import ApplicationRoutes from "./Routes";
import Loading from "./Loading";
import Notifier from "./Notifier";

import AutoReload from "./AutoReload";

const theme = createTheme({
  palette: { secondary: { main: colorSecondary } },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#c0d6f373", // substitua '#desiredColor' pela cor desejada
        },
      },
    },
  },
});

const App = ({ loading }) => (
  <ThemeProvider theme={theme}>
    {/* TODO teste de StylesProvider injectFirst */}
    {/* <StylesProvider injectFirst> */}
    <Suspense fallback={<AutoReload />}>
      <Helmet>
        <title>On.Loc</title>
      </Helmet>
      <CssBaseline />
      <Notifier />
      <Loading loading={loading} />
      <ApplicationRoutes />
    </Suspense>
    {/* </StylesProvider> */}
  </ThemeProvider>
);

const mapStateToProps = (state) => ({
  loading: state.utils.loading,
});

export default connect(mapStateToProps)(App);
