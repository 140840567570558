import { call, put, all, select } from 'redux-saga/effects';

import { backendGet } from '../../../config/api';
import * as AuthActions from '../auth/actions';
import * as UtilsActions from './actions';

export function* getCountNewMessages() {
    try {
        const token = yield select(state => state.auth.token);
        if (!token) yield put(AuthActions.getLogged());
        const data = yield call(
            backendGet,
            'utils/count',
            { headers: { olsystem: token } }
        );
        if (!data.error) yield put(UtilsActions.setCountNewMessages(data));
        else yield handleError(data);
    } catch(err) {
        yield put(UtilsActions.enqueueSnackbar('error', '(#US-GCNM01) - Falha ao buscar as novas mensagens!'));
    }
}

// -------------------------------------------------- LOCAL FUNCTIONS
function* handleError(data) {
    if (data.status === 401) yield put(AuthActions.unauthenticated());
    else if (data.status === 403) yield put(AuthActions.unauthorized());
    else yield all(data.msg.map(m => put(UtilsActions.enqueueSnackbar('error', m))));
}