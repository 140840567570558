import {
  FormControl,
  Grid,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import * as Helper from "../../../utils/helper";
import * as AccountApi from "@api/backend/account";
import useStyles from "@styles";
import { useDispatch } from "react-redux";
import * as UtilsActions from "@store/ducks/utils/actions";

const RemoveCancelAccount = (data) => {
  const { account, close, open } = data;

  const classes = useStyles();
  const dispatch = useDispatch();
  const handleCancel = () => {
    close(false);
  };
  const onSubmit = async () => {
    dispatch(UtilsActions.setLoading());

    if (account) {
      dispatch(UtilsActions.setLoading());
      await AccountApi.updateFieldsAccountReceive(account.account_id, {
        ...account,
        price: Helper.realToNumber(account.price),
        tax: Helper.realToNumber(account.tax),
        discount: Helper.realToNumber(account.discount),
        total: Helper.realToNumber(account.total),
        received: 0,
      });
    //   close(false);

      dispatch(UtilsActions.resetLoading());
      dispatch(
        UtilsActions.enqueueSnackbar("success", `Conta atualizada com sucesso!`)
      );
    }
  };

  return (
    open && (
      <>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={onSubmit}>
            <DialogTitle id="alert-dialog-title">
              {"Reverter Cancelamento"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.gridPadding}>
                    <FormControl required={true} fullWidth={true}>
                      Ao confirmar você vai definir o status dessa conta como
                      "EM ABERTO" e remover o motivo de cancelamento inicial.
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                autoFocus
              >
                Confirmar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    )
  );
};

export default RemoveCancelAccount;
