import * as Types from './types';

const INITIAL_STATE = {
    accountTypes: [],
    accountTypesDate: null,
    brands: [],
    brandsDate: null,
    providers: [],
    providersDate: null,
    clients: [],
    clientsDate: null,
    categories: [],
    categoriesDate: null,
    configurations: [],
    configurationsDate: null,
    models: [],
    modelsDate: null,
    services: [],
    servicesDate: null,
    users: [],
    usersDate: null,
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case Types.SET_ACCOUNT_TYPES:
            return { ...state, accountTypes: action.payload, accountTypesDate: Date.now() };
        case Types.SET_BRANDS:
            return { ...state, brands: action.payload, brandsDate: Date.now() };
        case Types.SET_PROVIDERS:
            return { ...state, providers: action.payload, providersDate: Date.now() };
        case Types.SET_CLIENTS:
            return { ...state, clients: action.payload, clientsDate: Date.now() };
        case Types.SET_CATEGORIES:
            return { ...state, categories: action.payload, categoriesDate: Date.now() };
        case Types.SET_CONFIGURATIONS:
            return { ...state, configurations: action.payload, configurationsDate: Date.now() };
        case Types.SET_MODELS:
            return { ...state, models: action.payload, modelsDate: Date.now() };
        case Types.SET_SERVICES:
            return { ...state, services: action.payload, servicesDate: Date.now() };
        case Types.SET_USERS:
            return { ...state, users: action.payload, usersDate: Date.now() };
		default:
			return state;
	}
}

export default reducer;
