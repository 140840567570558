import React, { createContext, useState, useContext, useEffect } from "react";
import AccountCreateReceive from "./AccountCreateReceive";
import AccountCreatePayment from "./AccountCreatePayment";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../store";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenPayment, setIsDialogOpenPayment] = useState(false);
  const [accountId, setAccountId] = useState(false);
  const [forceUpdate, setForceUpate] = useState(false);
  const location = useLocation();
  const history = useNavigate();

  const logged = useSelector((state) => state.auth.logged);
  const state = useSelector((state) => state);

  const openDialog = (id, path) => {
    const currentURL = location.pathname + location.search;

    const updatedURL = currentURL + `#${path}=` + id;

    history(updatedURL);

    id = id === "null" ? null : id;

    setAccountId(id);
    if (path === "recebimento") {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpenPayment(true);
    }
  };

  const closeDialog = () => {
    const updatedURL = location.pathname.split("#")[0];

    history(updatedURL);

    setIsDialogOpen(false);
    setIsDialogOpenPayment(false);
  };

  const defineAccountId = (accountId) => {
    setAccountId(accountId);
  };

  const checkAccountFragment = () => {
    if (location.hash.indexOf("#recebimento=") > -1) {
      openDialog(location.hash.split("#recebimento=")[1], "recebimento");
    } else if (location.hash.indexOf("#pagamento=") > -1) {
      openDialog(location.hash.split("#pagamento=")[1], "pagamento");
    } else {
      closeDialog();
    }
  };

  useEffect(() => {
    const handlePopstate = (event) => {
      if (event.type === "popstate") {
        checkAccountFragment();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    checkAccountFragment();
  }, [dispatch]);

  return (
    <DialogContext.Provider
      store={store}
      value={{
        isDialogOpen,
        isDialogOpenPayment,
        openDialog,
        closeDialog,
        defineAccountId,
        setForceUpate,
        forceUpdate,
        dispatch,
        checkAccountFragment,
        state,
        accountId,
      }}
    >
      {children}

      {isDialogOpen && <AccountCreateReceive></AccountCreateReceive>}
      {isDialogOpenPayment && <AccountCreatePayment></AccountCreatePayment>}
    </DialogContext.Provider>
  );
};

export const useDialogAccountDetail = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
