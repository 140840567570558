import React from 'react';
import { Dialog, DialogContent, Typography, LinearProgress, Grid, makeStyles } from '@material-ui/core';

import { colorText } from '../config/colors'

// ------------------------------------------------------------------------------------------------------------------ UTILS
const useStyles = makeStyles(theme => ({
	titleAlert: {
		marginTop: "10px",
		fontSize: 25,
		fontWeight: 600,
		color: colorText,
		textAlign: "center",
	},
	messageAlert: {
		marginBottom: "10px",
		fontSize: 16,
		fontWeight: 400,
		color: colorText,
		textAlign: "center",
    },
    linearBar: {
        height: '15px',
        borderRadius: 5,
    },
	borderRadius: {
		borderRadius: 30
	},
}));

const Loading = ({ loading }) => {
	const classes = useStyles();
	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={loading.start}
			className={classes.borderRadius}
		>
			<DialogContent>
				<LinearProgress className={classes.linearBar} />
				<Grid container>
					<Grid item xs={12}>
						<Typography className={classes.titleAlert}>{loading.title}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.messageAlert}>{loading.message}</Typography>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

export default Loading;