import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import { PDFViewer } from "@react-pdf/renderer";

import * as QuotationsApi from "@api/backend/quotation";
import * as AuxActions from "@store/ducks/aux/actions";

import styles from "@styles/pdf";

import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Doc from "../components/QuotationFilePDF";

const QuotationFile = () => {
  const { quotationId } = useParams();
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.aux.categories);

  useEffect(() => {
    async function fetchData() {
      setData(await QuotationsApi.getSingleQuotation(quotationId));
      dispatch(AuxActions.getCategories());
    }
    fetchData();
  }, [quotationId]);

  return (
    data &&
    categories && (
      <>
        <Helmet>
          <title>On.Loc | teste</title>
        </Helmet>
        <PDFViewer style={styles.pdfViewer}>
          <Doc data={data} categories={categories}></Doc>
        </PDFViewer>
      </>
    )
  );
};

export default QuotationFile;
