import store from "../../store";
import * as api from ".";
import * as AuthActions from "../../store/ducks/auth/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";

export async function getDomains(domain) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(`domains/list`, {
        params: {
          domain: domain,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACCGL1) - Falha ao buscar categorias!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

// -------------------------------------------------- LOCAL FUNCTIONS
function handleError(data) {
  if (data.status === 401) store.dispatch(AuthActions.unauthenticated());
  else if (data.status === 403) store.dispatch(AuthActions.unauthorized());
  else
    data.msg.map((m) =>
      store.dispatch(UtilsActions.enqueueSnackbar("error", m))
    );
}
