import * as Types from './types';

export const getAccountTypes = () => ({ type: Types.GET_ACCOUNT_TYPES });
export const setAccountTypes = (payload) => ({ type: Types.SET_ACCOUNT_TYPES, payload });
export const getProviders = () => ({ type: Types.GET_PROVIDERS });
export const setProviders = (payload) => ({ type: Types.SET_PROVIDERS, payload });
export const getBrands = () => ({ type: Types.GET_BRANDS });
export const setBrands = (payload) => ({ type: Types.SET_BRANDS, payload });
export const getCategories = () => ({ type: Types.GET_CATEGORIES });
export const setCategories = (payload) => ({ type: Types.SET_CATEGORIES, payload });
export const getClients = () => ({ type: Types.GET_CLIENTS });
export const setClients = (payload) => ({ type: Types.SET_CLIENTS, payload });
export const getConfigurations = () => ({ type: Types.GET_CONFIGURATIONS });
export const setConfigurations = (payload) => ({ type: Types.SET_CONFIGURATIONS, payload });
export const getModels = () => ({ type: Types.GET_MODELS });
export const setModels = (payload) => ({ type: Types.SET_MODELS, payload });
export const getServices = () => ({ type: Types.GET_SERVICES });
export const setServices = (payload) => ({ type: Types.SET_SERVICES, payload });
export const getUsers = () => ({ type: Types.GET_USERS });
export const setUsers = (payload) => ({ type: Types.SET_USERS, payload });