import * as Types from './types';

// ---------------------------------------------------------------------------------------------------- LOADING
export const setLoading = (title = 'Carregando...', message = 'Por favor, aguarde um momento!') => ({
    type: Types.SET_LOADING,
	payload: { title, message }
});
export const resetLoading = () => ({ type: Types.RESET_LOADING });

// ---------------------------------------------------------------------------------------------------- SNACKBAR
export const enqueueSnackbar = (variant, message, time) => {
    return {
        type: Types.ENQUEUE_SNACKBAR,
        notification: {
            message,
            options: {
                variant,
                // persist: true,
                autoHideDuration: time ? time:2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            },
            key: new Date().getTime() + Math.random(),
        },
    };
};
// dismiss all if no key has been defined
export const closeSnackbar = key => ({ type: Types.CLOSE_SNACKBAR, dismissAll: !key, key });
export const removeSnackbar = key => ({ type: Types.REMOVE_SNACKBAR, key });

// ---------------------------------------------------------------------------------------------------- COUNT NEW MESSAGES
export const getCountNewMessages = () => ({ type: Types.GET_COUNT_NEW_MESSAGES });
export const setCountNewMessages = (payload) => ({ type: Types.SET_COUNT_NEW_MESSAGES, payload });
