import axios from 'axios';
import { API } from './constants';

export const handleError = (error) => {
    // https://github.com/axios/axios#handling-errors
    const err = { error: true, status: 0, msg: [] }
    if (error.response) {
        err.status = error.response.status;
        err.msg = error.response.data.err;
    } else if (error.request) {
        err.status = 500;
        err.msg = ['Banco de dados desconectado!'];
    } else {
        err.status = 500;
        err.msg = [error.message];
    }
    return err;
}

const backend = axios.create({
    baseURL: API.BACKEND
});

export const backendGet = (url, data, options) => backend.get(url, data, options).then(result => result.data).catch(err => handleError(err));
export const backendPost = (url, data, options) => backend.post(url, data, options).then(result => result.data).catch(err => handleError(err));
export const backendPut = (url, data, options) => backend.put(url, data, options).then(result => result.data).catch(err => handleError(err));
export const backendDelete = (url, data, options) => backend.delete(url, data, options).then(result => result.data).catch(err => handleError(err));
// const receita = axios.create({
//     baseURL: API.RECEITA
// });

// export const receitaGet = (url, data, options) => receita.get(url, data, options).then(result => result.data).catch(err => handleError(err));