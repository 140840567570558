import React from 'react';

import { Container, Grid, makeStyles } from '@material-ui/core';

import Copyright from './Copyright';

import Logo from '../assets/logo-color-sm.png';

const internalStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor: theme.palette.grey[200],
    },
    logo: {
        maxWidth: '200px',
        maxHeight: '100px',
        marginTop: 10,
    },
}));

const LayoutPdf = ({ children }) => {
    const internalClasses = internalStyles();

    return <>
        <Grid container>
            {children}
        </Grid>
        <footer className={internalClasses.footer}>
            <Container maxWidth="md">
                <Grid container>
                    <Grid item xs style={{ textAlign: 'center' }}>
                        <img src={Logo} alt='On.Loc' className={internalClasses.logo} />
                    </Grid>
                    <Grid item xs><Copyright /></Grid>
                </Grid>
            </Container>
        </footer>
    </>;
};

export default LayoutPdf;