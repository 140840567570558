import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter  } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

import App from "./components/App";
import store from "./store";
import history from "./config/history";
import messages_pt from "./locales/pt-br.json";
import { IntlProvider } from "react-intl";

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={"pt-BR"} messages={messages_pt}>
      <BrowserRouter  location={history.location} navigator={history}>
        <SnackbarProvider preventDuplicate>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);
